import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Row,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import ClientInitialBalanceList from "../AdminComponents/ClientPages/ClientInitialBalanceList";
import { DisplayContext } from "../Context/displayContext";
const { Text } = Typography;

function ClientInfo() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const location = useLocation();
  const navigate = useNavigate();
  const [, copy] = useCopyToClipboard();
  const [canEditClient, setCanEditClient] = useState<boolean>(false);
  const { clientId } = useParams();
  const [client, setClient] = useState<any>({});
  const [userList, setUserList] = useState<any[]>();
  const [showAllClient, setShowAllClient] = useState<boolean>(true);
  const [assetPrices, setAssetPrices] = useState<any>();
  const [canGetWallet, setCanGetWallet] = useState<boolean>(false);
  const [custodyWallets, setCustodyWallets] = useState<any[]>([]);
  const [stakingWallets, setStakingWallets] = useState<any[]>([]);
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(true);
  const [isLoadingWallet, setIsLoadingWallet] = useState<boolean>(true);
  const [feePolicyAssignedCSDY, setFeePolicyAssignedCSDY] =
    useState<string>("");
  const [feePolicyAssignedSTKG, setFeePolicyAssignedSTKG] =
    useState<string>("");
  const [feePolicyAssignedTRDG, setFeePolicyAssignedTRDG] =
    useState<string>("");

  const [totalAuc, setTotalAuc] = useState<number>(0);
  const [totalStakingSum, setTotalStakingSum] = useState<number>(0);
  const [personalWallets, setPersonalWallets] = useState<any[]>([]);

  const fetchWallets = useCallback(() => {
    setIsLoadingWallet(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-wallets",
      params: {
        clientId: clientId ? clientId : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        setAssetPrices(res.data.assetPrices);
        let allSubWallets: any[] = [];
        setPersonalWallets(
          res.data.wallets?.filter((row: any) => row.isClientPersonal),
        );
        if (res.data.wallets) {
          res.data.wallets.forEach((t: any) => {
            const subWallets = t.subWallets.map((s: any) => {
              return {
                ...s,
                walletAddress: t.walletAddress,
                walletId: t.id,
                clientAccountNumber: t.clientAccount
                  ? t.clientAccount.accountNumber
                  : "",
              };
            });
            allSubWallets = allSubWallets.concat(subWallets);
          });
        }
        const c =
          allSubWallets && allSubWallets.length
            ? allSubWallets.filter(
                (t: any) =>
                  t.walletType &&
                  t.walletType.walletCategory &&
                  t.walletType.walletCategory.name === "Custody",
              )
            : [];

        const s =
          allSubWallets && allSubWallets.length
            ? allSubWallets.filter(
                (t: any) =>
                  t.walletType &&
                  t.walletType.walletCategory &&
                  t.walletType.walletCategory.name === "Staking",
              )
            : [];
        setCustodyWallets(c);
        setStakingWallets(s);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingWallet(false);
      });
  }, [clientId]);

  const fetchData = useCallback((params = {}) => {
    setIsLoadingClient(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
      params: {
        encodedClientId: clientId ? clientId : "",
      },
      withCredentials: true,
    })
      .then((res) => {
        setCanEditClient(res.data.canEdit);
        setClient(res.data.client);
        setCanGetWallet(res.data.canGetWallet);
        if (res.data.canGetWallet) {
          fetchWallets();
        }
      })
      .catch(() => {
        toast.error("Unable to fetch client info", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/bo/clients");
      })
      .finally(() => {
        setIsLoadingClient(false);
      });
    // eslint-disable-next-line
  }, []);

  const [initialBalanceFetched, setInitialBalancFetched] =
    useState<boolean>(false);
  const [initialBalances, setInititalBalance] = useState<any[]>([]);

  const fetchCanAssignInitialBalace = useCallback((clientId: string) => {
    setInitialBalancFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/initial-balance",
      params: {
        encodedClientId: clientId,
      },
      withCredentials: true,
    })
      .then((res) => {
        setInititalBalance(res.data.clientInitialBalances);
      })
      .finally(() => {
        setInitialBalancFetched(true);
      });
  }, []);

  useEffect(() => {
    if (clientId) fetchCanAssignInitialBalace(clientId);
  }, [clientId, fetchCanAssignInitialBalace]);

  const fetchPolicyAssigned = useCallback(() => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy-assgined",
      data: {
        id: clientId,
        type: "client",
      },
      withCredentials: true,
    }).then((res) => {
      const custodyPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "custody",
      );
      if (custodyPolicy) {
        setFeePolicyAssignedCSDY(custodyPolicy.feePolicy.name);
      }

      const stakingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "staking",
      );
      if (stakingPolicy) {
        setFeePolicyAssignedSTKG(stakingPolicy.feePolicy.name);
      }
      const tradingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "trading",
      );
      if (tradingPolicy) {
        setFeePolicyAssignedTRDG(tradingPolicy.feePolicy.name);
      }
    });
  }, [clientId]);

  useEffect(() => {
    if (client) {
      if (showAllClient) {
        setUserList(client.users);
      } else {
        setUserList(client.users.filter((u: any) => u.user?.isActive));
      }
    }
  }, [client, showAllClient]);

  const cardGridLeft = {
    width: "35%",
    textAlign: "left",
  } as const;
  const cardGridRight = {
    width: "65%",
    textAlign: "left",
  } as const;

  useEffect(() => {
    fetchData();
    fetchPolicyAssigned();
  }, [fetchData, fetchPolicyAssigned]);

  useEffect(() => {
    let aucSum = 0;
    let stakingSum = 0;
    if (custodyWallets && assetPrices) {
      for (const wallet of custodyWallets) {
        if (!wallet.isActive || !wallet.isReport) continue;
        if (wallet.displayBalance && assetPrices[wallet.assetId])
          aucSum += wallet.displayBalance * assetPrices[wallet.assetId];
      }
    }
    if (stakingWallets && assetPrices) {
      for (const wallet of stakingWallets) {
        if (!wallet.isActive || !wallet.isReport) continue;
        if (wallet.displayBalance && assetPrices[wallet.assetId])
          stakingSum += wallet.displayBalance * assetPrices[wallet.assetId];
      }
    }

    setTotalStakingSum(stakingSum);
    setTotalAuc(aucSum);
  }, [custodyWallets, stakingWallets, assetPrices]);
  return (
    <React.Fragment>
      {!isLoadingClient ? (
        <Content id="client-info">
          <Row>
            <Col span={4}>
              <Button
                onClick={() => {
                  if (location.state) {
                    navigate(location.state.from, { state: location.state });
                  } else {
                    navigate("/bo/clients");
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-circle-chevron-left" as IconProp}
                />
              </Button>
            </Col>
            <Col span={16} style={{ textAlign: "center" }}>
              <h2>{client.clientName}</h2>
            </Col>
            <Col span={3} style={{ float: "right", textAlign: "right" }}>
              <Button
                disabled={!canEditClient}
                onClick={() => {
                  clientId
                    ? navigate(
                        `/bo/client/update/${encodeURIComponent(clientId)}`,
                        { state: { from: window.location.pathname } },
                      )
                    : console.log("empty");
                }}
              >
                Edit
              </Button>
            </Col>
          </Row>

          <Row style={{ padding: "10px  0" }}>
            <Col lg={8} md={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Details</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Email:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  <Text
                    style={{ width: "100%" }}
                    ellipsis={{ tooltip: client.email }}
                  >
                    {client.email}
                  </Text>
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Is Active:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.clientName ? (
                    <Switch defaultChecked={client.isActive} disabled />
                  ) : (
                    <></>
                  )}
                </Card.Grid>
                <Card.Grid style={{ width: "100%" }}>
                  <Row>
                    <Col span={12}>
                      <b>Users:</b>
                    </Col>
                  </Row>
                </Card.Grid>
                <Card.Grid style={{ width: "100%" }}>
                  {userList && userList.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{ width: "100%", textAlign: "right" }}
                        >
                          <React.Fragment>
                            Show Inactive
                            <Switch
                              defaultChecked={showAllClient}
                              style={{ marginLeft: "10px" }}
                              onChange={() => setShowAllClient(!showAllClient)}
                            />
                          </React.Fragment>
                        </Card.Grid>
                        {userList.map((user: any, index: number) => {
                          return (
                            <Card.Grid
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                              key={index}
                            >
                              <Row style={{ padding: "10px  0" }}>
                                <Col span={2}>
                                  <b>{index + 1}</b>
                                </Col>
                                <Col span={22}>
                                  {user.user ? (
                                    <Link
                                      to={
                                        "/bo/user/info/" +
                                        encodeURIComponent(user.userId)
                                      }
                                      state={{ from: window.location.pathname }}
                                    >
                                      {user.user.lastName.trim()},
                                      {user.user.firstName.trim()}
                                    </Link>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </Card.Grid>
                          );
                        })}
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                          }}
                        >
                          <b>No users</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Accounts</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Deltec Account:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.deltecAccount ? client.deltecAccount : "N/A"}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Delchain Account:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.delchainAccount ? client.delchainAccount : "N/A"}
                </Card.Grid>
                <Card.Grid style={{ width: "100%" }}>
                  <b>Deltec Subaccounts:</b>
                </Card.Grid>
                <Card.Grid style={{ width: "100%" }}>
                  {client.clientAccounts && client.clientAccounts.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid style={{ width: "100%" }}>
                          <Row>
                            <Col span={8}>
                              <b>Account Number</b>
                            </Col>
                            <Col span={15} offset={1}>
                              <b>Label</b>
                            </Col>
                          </Row>
                        </Card.Grid>
                        {client.clientAccounts.map(
                          (clientAccount: any, index: number) => {
                            return (
                              <Card.Grid style={{ width: "100%" }} key={index}>
                                <Row className="client-info-sub-account-row">
                                  <Col span={8}>
                                    <Link
                                      to={
                                        "/bo/ebanking/ledger/" +
                                        clientAccount.id
                                      }
                                    >
                                      {clientAccount.accountNumber}
                                    </Link>
                                  </Col>
                                  <Col span={15} offset={1}>
                                    {clientAccount.label}
                                  </Col>
                                </Row>
                              </Card.Grid>
                            );
                          },
                        )}
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No accounts</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Infos</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Client Type:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.clientType ? client.clientType.name : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Bussiness Activity:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.businessActivityDspTerm
                    ? client.businessActivityDspTerm.value
                    : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Deltec RM:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.rmDspTerm ? client.rmDspTerm.value : ""}
                  {client.rmDspTerm &&
                  client.rmDspTerm.metadatas &&
                  client.rmDspTerm.metadatas.length ? (
                    <React.Fragment>
                      <Divider />
                      {client.rmDspTerm.metadatas.map((metadata: any) => (
                        <Row>
                          <b>{metadata.key}</b>: {metadata.value}
                        </Row>
                      ))}
                    </React.Fragment>
                  ) : (
                    <></>
                  )}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Client Ranking:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.ranking ? client.ranking : "N/A"}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Created By:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.createdBy ? client.createdBy.email : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Comment:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  <Space>{client.comment ? client.comment : "N/A"}</Space>
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Address</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Address Line 1:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.addressLine1 : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Address Line 2:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.addressLine2 : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Postcode:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.postcode : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>City:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.city : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>State:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.state : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Country:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address && client.address.country
                    ? client.address.country.nicename
                    : ""}
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Telegrams</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Group:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.telegramGroup}
                </Card.Grid>

                <Card.Grid style={cardGridLeft}>
                  <b>To Backup:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.clientName ? (
                    <Switch defaultChecked={client.toBackup} disabled />
                  ) : (
                    <></>
                  )}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Bot in Group:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.clientName ? (
                    <Switch defaultChecked={client.isBot} disabled />
                  ) : (
                    <></>
                  )}
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Fee Policies</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Custody:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {feePolicyAssignedCSDY}
                </Card.Grid>

                <Card.Grid style={cardGridLeft}>
                  <b>Staking:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {feePolicyAssignedSTKG}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Trading:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {feePolicyAssignedTRDG}
                </Card.Grid>
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Initial Balances</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                {initialBalanceFetched ? (
                  <ClientInitialBalanceList
                    clientId={clientId}
                    initialBalances={initialBalances}
                    canEdit={false}
                  />
                ) : (
                  <Row justify={"center"}>
                    <Spin />
                  </Row>
                )}{" "}
              </Card>
            </Col>
            {canGetWallet ? (
              isLoadingWallet ? (
                <Col span={24}>
                  <Row justify={"center"}>
                    <Spin size="large" />
                  </Row>
                </Col>
              ) : (
                <React.Fragment>
                  {custodyWallets && custodyWallets.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>Custody Wallets</Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                        extra={
                          <Space>
                            <b>AUC: </b>
                            {totalAuc.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 5,
                            })}
                          </Space>
                        }
                      >
                        <Row>
                          <Col span={2}>
                            <b>Asset</b>
                          </Col>
                          <Col span={2}>
                            <b>Address</b>
                          </Col>
                          <Col span={2}>
                            <b>Label</b>
                          </Col>
                          <Col span={3}>
                            <b>Client Account</b>
                          </Col>
                          <Col span={2}>
                            <b>Is Active</b>
                          </Col>
                          <Col span={2}>
                            <b>In Report</b>
                          </Col>
                          <Col span={2}>
                            <b>Is Placeholder</b>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <b>Quantity</b>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <b>Price</b>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <b>Value in USD</b>
                          </Col>
                        </Row>
                        <Divider />
                        {custodyWallets.map(
                          (subWallet: any, subIndex: number) => {
                            return (
                              <Row
                                key={subIndex}
                                align="middle"
                                className="client-info-wallet-row"
                              >
                                <Col span={2}>
                                  {subWallet.asset
                                    ? subWallet.asset.ticker
                                    : ""}
                                </Col>
                                <Col span={2}>
                                  <Tooltip title={subWallet.walletAddress}>
                                    <Link
                                      to={
                                        "/bo/wallet/view/" +
                                        encodeURIComponent(subWallet.walletId)
                                      }
                                      state={{
                                        from: window.location.pathname,
                                      }}
                                    >
                                      {subWallet.walletAddress &&
                                      subWallet.walletAddress.length > 4
                                        ? subWallet.walletAddress.substring(
                                            0,
                                            4,
                                          ) +
                                          "..." +
                                          subWallet.walletAddress.substr(-4, 4)
                                        : subWallet.walletAddress
                                        ? subWallet.walletAddress
                                        : ""}
                                    </Link>

                                    {subWallet.walletAddress ? (
                                      <Button
                                        style={{
                                          border: "none",
                                          margin: "0",
                                        }}
                                        onClick={() =>
                                          copy(subWallet.walletAddress)
                                        }
                                        size={"small"}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            "fa-regular fa-copy" as IconProp
                                          }
                                        />
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </Tooltip>
                                </Col>
                                <Col span={2}>
                                  <Link
                                    to={
                                      "/bo/wallet/view/" +
                                      encodeURIComponent(subWallet.walletId)
                                    }
                                    state={{
                                      from: window.location.pathname,
                                    }}
                                  >
                                    {subWallet.label}
                                  </Link>
                                </Col>
                                <Col span={3}>
                                  <Tooltip
                                    title={subWallet.clientAccountNumber}
                                  >
                                    {subWallet.clientAccountNumber}
                                  </Tooltip>
                                </Col>
                                <Col span={2}>
                                  <Switch
                                    disabled
                                    checked={subWallet.isActive}
                                  />
                                </Col>
                                <Col span={2}>
                                  <Switch
                                    disabled
                                    checked={subWallet.isReport}
                                  />
                                </Col>
                                <Col span={2}>
                                  <Switch
                                    disabled
                                    checked={subWallet.isPlaceholder}
                                  />
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  <Tooltip
                                    title={
                                      subWallet.displayBalance
                                        ? subWallet.displayBalance
                                        : "0.00"
                                    }
                                  >
                                    {subWallet.displayBalance
                                      ? subWallet.displayBalance.toLocaleString(
                                          "en-us",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 5,
                                          },
                                        )
                                      : "0.00"}
                                  </Tooltip>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  <Tooltip
                                    title={
                                      assetPrices &&
                                      subWallet.assetId &&
                                      assetPrices[subWallet.assetId]
                                        ? `$${assetPrices[
                                            subWallet.assetId
                                          ].toLocaleString("en-us")}`
                                        : `$${Number(0).toLocaleString(
                                            "en-us",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 4,
                                            },
                                          )}`
                                    }
                                  >
                                    $
                                    {assetPrices &&
                                    subWallet.assetId &&
                                    assetPrices[subWallet.assetId]
                                      ? assetPrices[
                                          subWallet.assetId
                                        ].toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : Number(0).toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                  </Tooltip>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  <Tooltip
                                    title={
                                      subWallet.displayBalance &&
                                      assetPrices &&
                                      assetPrices[subWallet.assetId]
                                        ? `$${(
                                            assetPrices[subWallet.assetId] *
                                            subWallet.displayBalance
                                          ).toLocaleString("en-us")}`
                                        : `$${Number(0).toLocaleString(
                                            "en-us",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            },
                                          )}`
                                    }
                                  >
                                    $
                                    {subWallet.displayBalance &&
                                    assetPrices &&
                                    assetPrices[subWallet.assetId]
                                      ? (
                                          assetPrices[subWallet.assetId] *
                                          subWallet.displayBalance
                                        ).toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : Number(0).toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                  </Tooltip>
                                </Col>
                              </Row>
                            );
                          },
                        )}
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>Custody Wallets</Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No wallets</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}

                  {stakingWallets && stakingWallets.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>Staking Wallets</Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                        extra={
                          <Space>
                            <b>AUM: </b>
                            {totalStakingSum.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Space>
                        }
                      >
                        <Row>
                          <Col span={2}>
                            <b>Asset</b>
                          </Col>
                          <Col span={2}>
                            <b>Address</b>
                          </Col>
                          <Col span={2}>
                            <b>Label</b>
                          </Col>
                          <Col span={3}>
                            <b>Client Account</b>
                          </Col>
                          <Col span={2}>
                            <b>Is Active</b>
                          </Col>
                          <Col span={2}>
                            <b>In Report</b>
                          </Col>
                          <Col span={2}>
                            <b>Is Placeholder</b>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <b>Quantity</b>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <b>Price</b>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <b>Value in USD</b>
                          </Col>
                        </Row>
                        <Divider />
                        {stakingWallets.map(
                          (subWallet: any, subIndex: number) => {
                            return (
                              <Row
                                key={subIndex}
                                align="middle"
                                className="client-info-wallet-row"
                              >
                                <Col span={2}>
                                  {subWallet.asset
                                    ? subWallet.asset.ticker
                                    : ""}
                                </Col>
                                <Col span={2}>
                                  <Tooltip title={subWallet.walletAddress}>
                                    <Link
                                      to={
                                        "/bo/wallet/view/" +
                                        encodeURIComponent(subWallet.walletId)
                                      }
                                      state={{
                                        from: window.location.pathname,
                                      }}
                                    >
                                      {subWallet.walletAddress &&
                                      subWallet.walletAddress.length > 4
                                        ? subWallet.walletAddress.substring(
                                            0,
                                            4,
                                          ) +
                                          "..." +
                                          subWallet.walletAddress.substr(-4, 4)
                                        : subWallet.walletAddress
                                        ? subWallet.walletAddress
                                        : ""}
                                    </Link>
                                    {subWallet.walletAddress ? (
                                      <Button
                                        style={{
                                          border: "none",
                                          margin: "0",
                                        }}
                                        onClick={() =>
                                          copy(subWallet.walletAddress)
                                        }
                                        size={"small"}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            "fa-regular fa-copy" as IconProp
                                          }
                                        />
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </Tooltip>
                                </Col>
                                <Col span={2}>
                                  <Link
                                    to={
                                      "/bo/wallet/view/" +
                                      encodeURIComponent(subWallet.walletId)
                                    }
                                    state={{
                                      from: window.location.pathname,
                                    }}
                                  >
                                    {subWallet.label}
                                  </Link>
                                </Col>
                                <Col span={3}>
                                  <Tooltip
                                    title={subWallet.clientAccountNumber}
                                  >
                                    {subWallet.clientAccountNumber}
                                  </Tooltip>
                                </Col>
                                <Col span={2}>
                                  <Switch
                                    disabled
                                    checked={subWallet.isActive}
                                  />
                                </Col>
                                <Col span={2}>
                                  <Switch
                                    disabled
                                    checked={subWallet.isReport}
                                  />
                                </Col>
                                <Col span={2}>
                                  <Switch
                                    disabled
                                    checked={subWallet.isPlaceholder}
                                  />
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  <Tooltip
                                    title={
                                      subWallet.displayBalance
                                        ? subWallet.displayBalance
                                        : "0.00"
                                    }
                                  >
                                    {subWallet.displayBalance
                                      ? subWallet.displayBalance.toLocaleString(
                                          "en-us",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 8,
                                          },
                                        )
                                      : "0.00"}
                                  </Tooltip>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  <Tooltip
                                    title={
                                      assetPrices &&
                                      subWallet.assetId &&
                                      assetPrices[subWallet.assetId]
                                        ? `$${assetPrices[
                                            subWallet.assetId
                                          ].toLocaleString("en-us")}`
                                        : `$${Number(0).toLocaleString(
                                            "en-us",
                                          )}`
                                    }
                                  >
                                    $
                                    {assetPrices &&
                                    subWallet.assetId &&
                                    assetPrices[subWallet.assetId]
                                      ? assetPrices[
                                          subWallet.assetId
                                        ].toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : Number(0).toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                  </Tooltip>
                                </Col>
                                <Col span={3} style={{ textAlign: "right" }}>
                                  <Tooltip
                                    title={
                                      subWallet.displayBalance &&
                                      assetPrices &&
                                      subWallet.assetId
                                        ? `$${(
                                            assetPrices[subWallet.assetId] *
                                            subWallet.displayBalance
                                          ).toLocaleString("en-us")}`
                                        : `$${Number(0).toLocaleString(
                                            "en-us",
                                          )}`
                                    }
                                  >
                                    $
                                    {subWallet.displayBalance &&
                                    subWallet.assetId
                                      ? (
                                          assetPrices[subWallet.assetId] *
                                          subWallet.displayBalance
                                        ).toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : Number(0).toLocaleString("en-us", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                  </Tooltip>
                                </Col>
                              </Row>
                            );
                          },
                        )}
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>Staking Wallets</Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No wallets</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}

                  {personalWallets?.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>Whitelist Addresses</Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Row>
                          <Col xs={24} md={10}>
                            <b>Address</b>
                          </Col>
                          <Col xs={12} md={2}>
                            <b>Risk</b>
                          </Col>
                          <Col xs={24} md={10}>
                            <b>Label</b>
                          </Col>
                          <Col xs={24} md={2}>
                            <b>Is Client Personal</b>
                          </Col>
                        </Row>
                        <Divider />
                        {personalWallets.map(
                          (wallet: any, subIndex: number) => {
                            return (
                              <Row
                                key={subIndex}
                                align="middle"
                                className="client-info-wallet-row"
                              >
                                <Col xs={24} md={10}>
                                  <Tooltip title={wallet.walletAddress}>
                                    <Link
                                      to={
                                        "/bo/wallet/view/" +
                                        encodeURIComponent(wallet.id)
                                      }
                                      state={{
                                        from: window.location.pathname,
                                      }}
                                    >
                                      {wallet.walletAddress}
                                    </Link>
                                    {wallet.walletAddress ? (
                                      <Button
                                        style={{
                                          border: "none",
                                          margin: "0",
                                        }}
                                        onClick={() =>
                                          copy(wallet.walletAddress)
                                        }
                                        size={"small"}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            "fa-regular fa-copy" as IconProp
                                          }
                                        />
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </Tooltip>
                                </Col>
                                <Col xs={12} md={2}>
                                  {wallet.walletRisk ? (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <Row>
                                            Risk: {wallet.walletRisk?.riskName}
                                          </Row>
                                          <Row>
                                            Date:{" "}
                                            {new Date(
                                              wallet.walletRisk?.timestamp,
                                            ).toLocaleString("en-us", {
                                              timeZone: displayTimezone,
                                              timeZoneName: "short",
                                            })}
                                          </Row>
                                          <Row style={{maxWidth: '100%', wordBreak: 'break-word' }}>
                                            Raw Data:{" "}
                                            {wallet.walletRisk?.walletRiskJSON}
                                          </Row>
                                        </React.Fragment>
                                      }
                                    >
                                      <i
                                        className={`dcl-risk-info dcl-risk-${wallet.walletRisk?.riskName}`}
                                      >
                                        {wallet.walletRisk?.riskName?.[0]}
                                      </i>
                                    </Tooltip>
                                  ) : undefined}
                                </Col>
                                <Col xs={24} md={10}>
                                  <Link
                                    to={
                                      "/bo/wallet/view/" +
                                      encodeURIComponent(wallet.id)
                                    }
                                    state={{
                                      from: window.location.pathname,
                                    }}
                                  >
                                    {wallet.label}
                                  </Link>
                                </Col>
                                <Col xs={12} md={2}>
                                  <Switch checked disabled />
                                </Col>
                              </Row>
                            );
                          },
                        )}
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>Whitelist Addresses</Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No wallets</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}
                </React.Fragment>
              )
            ) : (
              <></>
            )}
          </Row>
        </Content>
      ) : (
        <Content>
          <Layout
            style={{
              paddingTop: "30vh",
              minHeight: "100vh",
            }}
          >
            <Spin size="large" />
          </Layout>
        </Content>
      )}
    </React.Fragment>
  );
}
export default ClientInfo;
